import React from 'react';
import BaseModule from '../../logic/module/BaseModule';
import { AppRoute } from '../../components';

export const NAME = 'my-account';

export default class MyAccountModule extends BaseModule {
  getName() {
    return NAME;
  }

  getPermission() {
    return 'my-account.*';
  }

  getNavigation() {
    return [
      {
        label: 'My account',
        icon: 'user',
        path: '/my-account',
        permission: 'my-account.*'
      }
    ];
  }

  getRoutes(currentUrl = '') {
    return [
      <AppRoute
        path={`${currentUrl}my-account`}
        load={() => import('../my-account/MyAccount')}
        // component={MyAccount}
      />
    ];
  }
}
