import React from 'react';
import BaseModule from '../../logic/module/BaseModule';
import { AppRoute } from '../../components';

export const NAME = 'cards';

export default class CardsModule extends BaseModule {
  getName() {
    return NAME;
  }

  getPermission() {
    return 'card.*';
  }

  getNavigation() {
    return [
      {
        label: 'Cards',
        icon: 'credit-card',
        path: '/cards',
        permission: 'cards.*'
      }
    ];
  }

  getRoutes(currentUrl = '') {
    return [
      <AppRoute
        path={`${currentUrl}cards`}
        load={() => import('../../modules/cards/Cards')}
      />
    ];
  }
}
